import React, { PureComponent } from 'react'
import { HashRouter as Router } from 'react-router-dom'

import Root from './Root'

export class RootRouter extends PureComponent {
  render() {
    return (
      <Router>
        <Root />
      </Router>
    )
  }
}