import React, { PureComponent } from 'react'
export class ErrorBoundary extends PureComponent {
  constructor (props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError (error) {
    // Update state so the next render will show the fallback UI.
    console.error(error)
    return { hasError: true, error }
  }

  componentDidCatch (error, info) {
    // You can also log the error to an error reporting service
    console.error(error)
    // logErrorToMyService(error, info)
  }

  render () {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1 style={{ textAlign: 'center', color: '#14869c', marginTop: '10%' }}>DNC Something went wrong. </h1>
    }

    return this.props.children
  }
}
