import React, { PureComponent } from 'react'

import './styles/loader.scss'

// THIS IS A PLACEHOLDER LOADER

export class Loader extends PureComponent {
  render() {
    let isFullScreen = this.props.isFullScreen === undefined || this.props.isFullScreen;
    let isWidget = this.props.isWidget;
    let containerClass = 'container__loader';
    containerClass += !isFullScreen ? " graph-loader" : "";
    containerClass += isWidget ? " small-widget-loader" : "";
    // return (
    //   <div className='spinner'>
    //     <svg viewBox='0 0 66 66' xmlns='http://www.w3.org/2000/svg' className='spinner__svg'>
    //       <circle fill='none' strokeWidth='8' strokeLinecap='round' cx='33' cy='33' r='28' className='spinner__path' />
    //     </svg>
    //     <svg viewBox='0 0 66 66' xmlns='http://www.w3.org/2000/svg' className='spinner__svg'>
    //       <circle fill='none' strokeWidth='8' strokeLinecap='round' cx='33' cy='33' r='28' className='spinner__path' />
    //     </svg>
    //     <svg viewBox='0 0 66 66' xmlns='http://www.w3.org/2000/svg' className='spinner__svg'>
    //       <circle fill='none' strokeWidth='8' strokeLinecap='round' cx='33' cy='33' r='28' className='spinner__path' />
    //     </svg>
    //     <svg viewBox='0 0 66 66' xmlns='http://www.w3.org/2000/svg' className='spinner__svg'>
    //       <circle fill='none' strokeWidth='8' strokeLinecap='round' cx='33' cy='33' r='28' className='spinner__path' />
    //     </svg>
    //   </div>
    // )
    return (
      <>
        <div className={containerClass}>
          <div className='container__loader-bar' />
          <div className='container__loader-bar' />
          <div className='container__loader-bar' />
          <div className='container__loader-bar' />
        </div>
      </>
    )
  }
}
