import './styles/root.scss'
import { rootTheme } from './styles/style'
import React, { PureComponent, Suspense, lazy } from 'react'
import { Route, withRouter, Switch } from 'react-router-dom'
import { Notifier } from '../components/notifier/Notifier'
import { MuiThemeProvider } from "@material-ui/core";
import { ErrorBoundary } from '../components/error-boundary/ErrorBoundary'

import Init from './init/Init'
import { Loader } from '../components/loader/Loader'
const Home = lazy(() => import('./home/Home' /* webpackChunkName: "Home", webpackPreload: true  */))
const Login = lazy(() => import('./login/Login' /* webpackChunkName: "Login", webpackPreload: true  */))

class Root extends PureComponent {
  state = {
    showNotifications: false
  }
  componentDidMount() {

    document.body.addEventListener('mousedown', this.addMouse)
    document.body.addEventListener('keydown', this.removeMouse)
    window.addEventListener('touchstart', this.startedSwipe, { passive: true })
    window.addEventListener('touchmove', this.addSwipe, { passive: true })
    window.addEventListener('touchend', this.removeSwipe, { passive: true })
  }
  componentWillUnmount() {
    document.body.removeEventListener('mousedown', this.addMouse)
    document.body.removeEventListener('keydown', this.removeMouse)
    window.removeEventListener('touchstart', this.startedSwipe)
    window.removeEventListener('touchmove', this.addSwipe)
    window.removeEventListener('touchend', this.removeSwipe)

    if (this.swiperTimeout) {
      clearTimeout(this.swiperTimeout)
    }
  }
  addMouse = (e) => {
    document.body.classList.add('using-mouse')
  }
  removeMouse = (e) => {
    if (e.keyCode === 9) {
      document.body.classList.remove('using-mouse')
    }
  }
  startedSwipe = (e) => {
    this.swiping = true
  }
  addSwipe = (e) => {
    if (this.swiping) {
      document.body.classList.add('using-swipe')
    }
  }
  removeSwipe = (e) => {
    this.swiping = false

    if (this.swiperTimeout) {
      clearTimeout(this.swiperTimeout)
    }

    this.swiperTimeout = setTimeout(() => {
      document.body.classList.remove('using-swipe')
      this.swiperTimeout = null
    }, 250)
  }

  renderContent() {
    const { history } = this.props

    return (

      <ErrorBoundary>

        <Suspense fallback={<Loader />}>
          <Switch location={history.location}>
            <Route exact path='/' render={() => <Init/>} />
            <Route exact path='/login' render={() => <Login/>} />
            <Route render={() => <Home/>} />
          </Switch>
        </Suspense>
      </ErrorBoundary>
    )
  }

  render() {
    return (
      <MuiThemeProvider theme={rootTheme}>
        <React.Fragment>
          <div className='root'>
            {this.renderContent()}
            <footer>
              <Notifier />
            </footer>
          </div>
        </React.Fragment>
      </MuiThemeProvider>
    )
  }
}

export default withRouter(Root);