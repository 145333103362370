import { createMuiTheme } from "@material-ui/core";

const breakpointValues = {
    xs: 0,
    sm: 890,
    md: 1290,
    lg: 1680,
    xl: 1920,
};

export const rootTheme = createMuiTheme(
    {
        breakpoints: { values: breakpointValues },
        overrides: {
            MuiCollapse: {
                wrapper: {
                    display: 'block'
                },
                entered: {
                    overflow: 'hidden'
                }
            },
            MuiFormControl: {
                root: {
                    display: 'block'
                }
            },
            MuiSelect: {
                select: {
                    '&:focus': {
                        backgroundColor: 'rgba(0, 0, 0, 0.0)'
                    }
                }
            },
            MuiCheckbox: {
                colorPrimary: {
                    '&$checked': {
                        color: '#14869C',
                    }
                },
                colorSecondary: {
                    '&$checked': {
                        color: '#14869C',
                    }
                },
            },
            MuiDialogActions: {
                root: {
                    margin: '',
                    padding: 0
                }
            },
            MuiFormHelperText: {
                root: {
                    fontFamily: 'Lato',
                }
            },
            MuiFormLabel: {
                root: {
                    fontFamily: 'Lato',
                    '&$focused': {
                        color: '#14869C',
                        borderBottomColor: '#14869C',
                    },
                }
            },
            MuiInput: {
                underline: {
                    '&:after': {
                        borderBottomColor: '#14869C',
                    },
                    '&:selected': { //underline color when hovered 
                        borderBottomColor: 'green',
                    },
                }
            },
            MuiInputBase: {
                root: {
                    fontFamily: 'Lato',
                }
            },
            MuiInputLabel: {
                '&$focused': {
                    color: '#14869C'
                }
            },
            MuiMenuItem: {
                root: {
                    fontFamily: 'Lato',
                }
            },
            MuiPickersCalendarHeader: {
                switchHeader: {
                },
            },
            MuiPickersClock: {
                pin: {
                    backgroundColor: '#14869C',
                }
            },
            MuiPickersClockPointer: {
                thumb: {
                    borderColor: '#14869C',
                },
                pointer: {
                    backgroundColor: '#14869C',
                },
            },
            MuiPickersDay: {
                day: {
                    color: '#14869C',
                },
                isSelected: {
                    backgroundColor: '#14869C',
                },
                current: {
                    color: '#14869C',
                },
            },
            MuiPickerDTTabs: {
                tabs: {
                    backgroundColor: '#14869C'
                }
            },
            MuiPickersModal: {
                dialogAction: {
                    color: '#14869C',
                },
            },
            MuiPickersToolbar: {
                toolbar: {
                    backgroundColor: '#14869C',
                },
            },
            MuiTableCell: {
                head: {
                    fontFamily: 'Lato',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    color: '#333333'
                },
                root: {
                    borderBottom: '0px'
                },
                stickyHeader: {
                    color: 'rgba(0, 0, 0, 0.87)',
                    backgroundColor: 'white'
                }
            },
            MuiTypography: {
                body1: {
                    fontFamily: 'Lato',
                    color: 'rgba(0, 0, 0, 0.87)',
                    fontSize: '0.875rem'
                },
                body2: {
                    fontFamily: 'Lato'
                },
                h4: {
                    fontFamily: 'Lato',
                    color: 'rgba(0, 0, 0, 0.87)'
                },
                h6: {
                    fontFamily: 'Lato',
                },
                root: {
                    fontFamily: 'Lato',
                },
                subheading: {
                    fontFamily: 'Lato',
                },
                subtitle1: {
                    fontFamily: 'Lato',
                    color: 'rgba(0, 0, 0, 0.87)'
                },
                title: {
                    fontFamily: 'Lato',
                },
            },
        },
        palette: {
            primary: {
                main: '#14869c'
            }
        }
    }
);