import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'

class Init extends PureComponent {
  componentDidMount() {
    if (window.localStorage.getItem('token')) {
      this.props.history.replace('/home')
    } else {
      this.props.history.replace('/login')
    }
  }

  render() {
    return (
      <div />
    )
  }
}

export default withRouter(Init)
